import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  shows = [
    'Winning choreographer of Nach Baliye Season 5',
    'Winning choreographer of Nach Baliye Season 9',
    'Dance ke Super Kids',
    'Super Dancer Season 2',
    'DID Li’l Master Season 3',
    'Dance Plus Season 1 and 2',
    'Eka Peksha Ek Apsara Ali Season 7',
    'India’s got Talent Season 8',
    'Choreographer for India’s Got Talent, Season 9',
  ];
  awards = [
    'Zee Marathi Awards 2015',
    'Title song of Marathi Serial Dil Dosti Dunyadaari',
    'Title song of Hrudayat Vaje Something',
    'Gaurav Maharashtracha',
    'Laxmi Berde Awards',
    'Mahesh Kothare Awards',
    'Shambhar Numbari Ashok Saraf Awards',
    '53 Marathi Chitrapath sohla (State Awards)',
    'Choreographer of Marathi song “Rada Rada” (yet to be released).',
  ];

  realityShows = [
    { description: 'Assisted Omkar Shinde for Nach Baliye, season 9', name: 'Bharat Ghare' },
    { description: 'Assisted Omkar Shinde for Yuva Dancing Queen', name: 'Bharat Ghare' },
    { description: 'Assisted Omkar Shinde for Dancing Queen XXL', name: 'Bharat Ghare' },
    { description: 'Assisted Omkar Shinde for Super Dancer chapter 2', name: 'Bharat Ghare' },
    { description: 'Assisted Omkar Shinde for song Rada Rada', name: 'Arya Dongre' },
    { description: 'Assisted Omkar Shinde for title song of Hrudayat vaje something', name: 'Arya Dongre' },
    { description: 'Assisted for Dance plus season 3', name: 'Bharat Ghare' },
  ]
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  paragraphs: string[] = [
    `<span class="font-bold">Om Dance Academy</span> is run by a team of professionals who have been
     trained under Omkar Shinde to handle various departments, like teaching and training students,
     handling and managing class premises, handling social media accounts and pages, working on
     advertising and promotions, professional videographers and editors, costume designers, the legal
     team, separate team for corporate performances, sangeet choreographies, etc.`,
    `Omkar Shinde has always believed that, a team is not just built with professionals hired to do a
     job. He has always ensured that the environment of work is such where everyone is heard, respected,
     accepted and each one’s growth and learning is placed at the highest pedestal.`,
    `Less of a team and more of a family is what his exemplifies and stands for. The goodwill that Om
     Dance Academy has in the dance market is the result of the healthy and happy environment that Omkar
     Shinde has created among the instructors, choreographers and other teams.`,
    `Passion, practice and perseverance is the mantra that Omkar Shinde has passed on to his team, and
     the team takes utmost pride and esteem in living up to it. He has built a team that follows the
     footsteps of their leader and is known as one of the best teams of dancers and choreographers, who
     have always left their mark everywhere they go, be it studio rehearsals and training, reality or
     award shows, or competitions and events.`,
    `A team that is always ready and willing to do what it takes, to work for him and ensure that the
     goodwill of Om Dance Academy soars to greater heights with every undertaking.`
  ];

  aboutItems: string[] = [
    ` The name Omkar Shinde is second to none in the industry of dancers and choreographers. He has
      choreographed various dance reality shows in the Hindi as well as the Marathi Television industry.
      He was the winning choreographer of Nach Baliye Season 5 and Nach Baliye Season 9 and has worked as
      a choreographer for various award shows, chorepgraphed title songs for daily soaps, ads, commercials
      etc. Omkar Shinde is also the lead actor of a soon to be released film called “Annan”, paired
      opposite Prarthana Behere.`,
    ` Hard work, consistency and discipline, have always been his mantra and he profoundly believes that,
      a man’s dedication and commitment towards his work can take him to inconceivable places.`,
  ];

  meetUs: string[] = [
    `Omkar Shinde, is one of those people who believes in the ‘one for all and all for one’ ideology.
    ‘Team Omkar’, as he likes to address his group of instructors and choreographers, has always been an
    integral part of every project that is undertaken and signed by Omkar Shinde. Omkar Shinde has
    played the role of mentor, guide, teacher and friend for each of his team member, and has been a
    remarkable influence in their respective journeys of dance and artistry.`
  ];

  career1: string[] = [
    `  The man who believes that, a person may not be perfect with techniques but his levels of passion,
                    practice and perseverance always take him a step closer to perfection. Everyone who has either
                    worked with him or learnt under his guidance has been a witness to the level of dedication he has
                    towards his work.`,
    `A person who has always been grateful for every single opportunity, for every stage, for every
                    platform that he has stepped on and most importantly to the Lord, for bestowing him with the skills
                    and blessing him in every step of his journey. A man who always starts his projects by taking the
                    name of the lord and seeking his blessings.`,
    ` <span class="font-bold">“Believe that you can create magic through your movements and see yourself
                        transcend par excellence”</span>
                    is a teaching that reverberates in his very presence.`,
    `           Omkar Shinde started his dance journey at a mere age of 15 years. He took extensive training in
                    Bharatnatyam under his Guru Shri. Parimal Phadke, and alongside that, he also trained in various
                    other dance forms like Latin, Contemporary, etc. From a very young age, the urge of learning,
                    experimenting, creating and showcasing ran an adrenaline within him. A passionate and disciplined
                    dancer, who always dreamt of making his mark in the dance industry and be known as one of finest
                    choreographer of his time. This rush wasn’t a short lived one and still continues to be a constant
                    factor that drives him to better himself with every project that he undertakes.`
  ];

  carouselSlides = [
    {
      image: '../../assets/About/IMG_8252.JPG',
      title: 'Omkar Shinde & Team',
    },
    {
      image: '../../assets/About/omkar-shinde5.png',
      title: 'Omkar Shinde & Team',
    },
    {
      image: '../../assets/About-Dance/students.JPG',
      title: 'Omkar Shinde & Team',
    },
  ];
}
