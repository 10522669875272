<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-info">
          <h3>Om Dance Academy</h3>
          <p>Is a registered dance academy, <br> started by reality show dancer & choreographer – Omkar
            Shinde.<br><strong>Email:</strong> osdancecompany06@gmail.com<br> </p>
          <div class="social-links">
            <a target="_blank" href="https://www.youtube.com/@omkarshindesdancecompany11" class="youtube"><i
                class="bi bi-youtube"></i></a>
            <a target="_blank" href="https://www.facebook.com/p/OM-DANCE-Academy-100063724943566/" class="facebook"><i
                class="bi bi-facebook"></i></a>
            <a target="_blank" href="https://www.instagram.com/omdanceacademy?igsh=YjJoMmlxd2ZvMnZ1"
              class="instagram"><i class="bi bi-instagram"></i></a>
            <a target="_blank" href="mailto:osdancecompany06@gmail.com" class="google"><i class="bi bi-google"></i></a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 footer-links order-last order-lg-0" (click)="scrollToTop()">
          <h4>Useful Links</h4>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="about">About Us</a></li>
            <li><a routerLink="omkar-shinde">Omkar Shinde</a></li>
            <li><a routerLink="events">Events</a></li>
            <li><a routerLink="courses">Courses</a></li>
            <li><a routerLink="anubhav">Anubhav</a></li>
            <li><a routerLink="contact">Contact</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-contact">
          <h4>Kothrud Pune Branch</h4>
          <p>
            302 Venture apt.,
            above McDonald’s,<br>
            Kothrud Depot,
            paud road, pune<br>
            <strong>Phone:</strong> +91 86696 66936<br>

          </p>
        </div>

        <div class="col-lg-3 col-md-6 footer-contact">
          <h4>Nanded City Pune Branch</h4>
          <p>
            Shop no. F1, 1st floor, R.B. Ingale <br>
            plazza, besides Destination Centre,<br>
            Nanded City, Pune<br>
            <strong>Phone:</strong> +91 95295 64139<br>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">&copy; Copyright <strong><a href="https://techcognicsindia.com/"
          class="text-white text-decoration-none hover:color:red"
          target="_blank"><span>techcognicsindia.com</span></a></strong>. All
      Rights Reserved
    </div>
    <div class="credits">Designed by <a class="footers font-bold text-white text-decoration-none"
        href="https://techcognicsindia.com/" target="_blank">Techcognics India Pvt. Ltd.</a>
    </div>
  </div>
</footer>
<a class="back-to-top d-flex align-items-center justify-content-center" (click)="scrollToTop()"
  style="cursor: pointer;"><i class="bi bi-arrow-up-short"></i></a>