<section id="events" class="events section dark-background">
    <img src="../../assets/events/Students-photo.jpeg" alt="" data-aos="fade-in" class="">
    <div class="container d-flex flex-column align-items-center text-center mt-auto">
        <h2 data-aos="fade-up" data-aos-delay="100" class="">Discover Exciting Dance Events At<br><span>Om Dance
                Academy.</span></h2>
    </div>
    <div class="about-info mt-auto position-relative">
        <div class="container position-relative" data-aos="fade-up">
            <div class="row">
                <div class="col-lg-6">
                    <h2>About The Event</h2>
                    <p>Discover an unforgettable dance event at Om Dance Academy, featuring captivating showcases,
                        interactive workshops, and exciting collaborations. Whether you're new to dance or a seasoned
                        dancer, join us in celebrating the beauty and joy of dancing together. Keep an eye out for more
                        details and save the date for this inspiring event!.</p>
                </div>
                <div class="col-lg-3">
                    <h3>Where</h3>
                    <p>PAN India</p>
                </div>
                <div class="col-lg-3">
                    <h3>When</h3>
                    <p>Monday to Wednesday</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  -->
<section id="event-info" class="event-info section-bg">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-in">The Fusion of Corporate and Sangeet Events</h2>
        </div>
        <div class="row">
            <div class="col-md-6 d-flex" *ngFor="let event of events" [attr.data-aos]="event.aos">
                <div class="card">
                    <div class="card-img">
                        <img [src]="event.image" alt="...">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a>{{event.title}}</a></h5>
                        <p class="card-text">{{event.description}}</p>
                        <p>{{event.details}}</p>
                        <p *ngIf="event.extraDetails">{{event.extraDetails}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>