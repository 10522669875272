<!-- hero section -->
<section id="anubhav" class="anubhav section" (click)="scrollToTop()">
    <img src="../../assets/anubhav/banner.JPG" alt="hero" data-aos="fade-in">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 data-aos="fade-up">अनुभव :<span> Dance Show </span>
                </h2>
            </div>
        </div>
    </div>
</section>
<!--  anubhav intro -->
<section id="features" class="features section">
    <div class="container section-title" data-aos="fade-up">
        <span>Anubhav Dance Show Unveiled!</span>
        <h2>Anubhav</h2>
    </div>
    <div class="container">
        <div class="row gy-4 align-items-center features-item" *ngFor="let feature of features; let i = index">
            <div class="col-md-5 order-1 order-md-{{ i % 2 === 0 ? 2 : 1 }} d-flex align-items-center"
                [attr.data-aos]="'fade-right'">
                <img [src]="feature.image" class="img-fluid" alt="">
            </div><br>
            <div class="col-md-7 order-2 order-md-{{ i % 2 === 0 ? 1 : 2 }}" data-aos="fade-left">
                <h3>{{ feature.title }}</h3>
                <p>{{ feature.description }}</p>
            </div>
        </div>
    </div>
</section>