<section id="contact-Info" class="contact-Info section" (click)="scrollToTop()">
    <img src="../../assets/contact/contactUs-img.jpg" alt="hero" data-aos="fade-in">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 data-aos="fade-up" data-aos-delay="100">Contact us</h2>
            </div>
        </div>
    </div>
</section>
<!--  -->
<section id="contact" class="contact section">
    <div class="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
    </div>
    <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="row gx-lg-0 gy-4">
            <div class="col-lg-4">
                <div class="info-container d-flex flex-column align-items-center justify-content-center">
                    <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                        <i class="bi bi-geo-alt flex-shrink-0"></i>
                        <div>
                            <h3>Kothrud Pune Branch</h3>
                            <p>
                                302 Venture apt., above McDonald’s, Kothrud Depot paud road,
                                Pune
                            </p>
                            <p>+91 86696 66936</p>
                        </div>
                    </div>

                    <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                        <i class="bi bi-geo-alt flex-shrink-0"></i>
                        <div>
                            <h3>Nanded City Pune Branch </h3>
                            <p>
                                Shop no. F1, 1st floor, R. B. Ingale plazza, besides Destination
                                Centre, Nanded City
                            </p>
                            <p>+91 95295 64139</p>
                        </div>
                    </div>

                    <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                        <i class="bi bi-envelope flex-shrink-0"></i>
                        <div>
                            <h3>Email Us</h3>
                            <p>osdancecompany06@gmail.com</p>
                        </div>
                    </div>

                    <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                        <i class="bi bi-clock flex-shrink-0"></i>
                        <div>
                            <h3>Follow Us</h3>
                            <div class="links">
                                <a target="_blank" href="https://www.youtube.com/@omkarshindesdancecompany11" class="youtube"><i
                                        class="bi bi-youtube"></i></a>
                                <a target="_blank" href="https://www.facebook.com/p/OM-DANCE-Academy-100063724943566/"
                                    class="facebook"><i class="bi bi-facebook"></i></a>
                                <a target="_blank" href="https://www.instagram.com/omdanceacademy?igsh=YjJoMmlxd2ZvMnZ1"
                                    class="instagram"><i class="bi bi-instagram"></i></a>
                                <a target="_blank" href="mailto:osdancecompany06@gmail.com" class="google"><i
                                        class="bi bi-google"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <form name="editForm" role="form" novalidate (ngSubmit)="onSubmit()" [formGroup]="editForm"
                    class="contact-form" data-aos="fade" data-aos-delay="100">
                    <div class="row gy-4">
                        <div class="col-md-6">
                            <input type="text" name="name" class="form-control" id="name" formControlName="name"
                                placeholder="Your Name" />
                            <div
                                *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)">
                                <small class="form-text text-danger" *ngIf="editForm.get('name')?.errors?.['required']">
                                    Name is required.
                                </small>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <input type="text" name="contactNumber" class="form-control" id="contactNumber"
                                formControlName="contactNumber" placeholder="Your Number" />
                            <div
                                *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('contactNumber')!.dirty ||editForm.get('contactNumber')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('contactNumber')?.errors?.['required']">Contact Number is
                                    required.</small>
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please enter a 10-digit
                                    Mobile Number</small>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <input type="text" name="email" class="form-control" id="email" formControlName="email"
                                placeholder="Your Email" />
                            <div
                                *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('email')?.errors?.['required']">Email is required</small>
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('email')?.errors?.['email']">Please enter valid email.</small>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <textarea class="form-control" name="comments" rows="3" placeholder="Questions/ Comments"
                                formControlName="comments"></textarea>
                            <div
                                *ngIf="editForm.get('comments')!.invalid && (editForm.get('comments')!.dirty || editForm.get('comments')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('comments')?.errors?.['required']">This field is
                                    required</small>
                            </div>
                        </div>

                        <div *ngIf="showThankYouMessage" class="text-center text-success">
                            Thank You for contacting us. Our team will get back to you
                            shortly.
                        </div>

                        <div class="col-md-12 text-center">
                            <button [disabled]="editForm.invalid || isSaving" type="submit" value="send">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>