<!-- hero section -->
<section id="courses" class="courses section" (click)="scrollToTop()">
    <img src="../../assets/Courses/ODA-Fam.jpg" alt="hero" data-aos="fade-in">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 data-aos="fade-up" data-aos-delay="100">Dance Courses</h2>
            </div>
        </div>
    </div>
</section>
<!--  -->
<div class="box-wrapper" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <figure class="shape-box shape-box_half" *ngFor="let card of cards" data-aos="zoom-in-up">
        <img [src]="card.img" alt="">
        <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
        <figcaption>
            <div class="show-cont">
                <h3 class="card-no">{{ card.no }}</h3>
                <h4 class="card-main-title">{{ card.title }}<br>Batch</h4>
            </div>
            <p class="card-content">{{ card.content }}</p>
        </figcaption>
        <span class="after"></span>
    </figure>
</div>