<section id="hero" class="d-md-none">
  <div class="hero-container" data-aos="fade-up">
    <h1>Welcome To Om Dance Academy</h1>
    <a routerLink="courses" class="btn-get-started text-decoration-none" (click)="scrollToTop()">Explore Our Courses</a>
  </div>
</section>
<!--  -->
<section id="hero-desktop" class="d-none d-md-block">
  <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <ol class="carousel-indicators" id="hero-carousel-indicators">
      <li *ngFor="let slide of carouselSlides; let i = index" [attr.data-bs-target]="'#heroCarousel'"
        [attr.data-bs-slide-to]="i" [class.active]="i === 0"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div *ngFor="let slide of carouselSlides; let i = index" class="carousel-item" [class.active]="i === 0"
        [ngStyle]="{'background-image': 'url(' + slide.image + ')'}">
        <div class="carousel-container">
          <div class="container">
            <h2 class="animate__animated animate__fadeInDown" [innerHTML]="slide.title"></h2>
            <a [routerLink]="slide.buttonLink" class="btn-get-started text-decoration-none" (click)="scrollToTop()">{{
              slide.buttonText }}</a>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>
</section>
<!--  -->
<main id="main">
  <div id="about" class="about heroSec">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-lg-11">
          <div class="row justify-content-end">
            <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
              <div class="count-box py-5">
                <i class="bi bi-people"></i>
                <span data-purecounter-start="0" data-purecounter-end="9" data-purecounter-duration="2"
                  class="purecounter">{{ count2 + "+"}}</span>
                <p>Teachers</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
              <div class="count-box py-5">
                <i class="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="120" data-purecounter-duration="2"
                  class="purecounter">{{ count1 + "+"}}</span>
                <p>Happy Students</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
              <div class="count-box pb-5 pt-0 pt-lg-5">
                <i class="bi bi-clock"></i>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="2"
                  class="purecounter">{{ count3 + "+"}}</span>
                <p>Years of experience</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
              <div class="count-box pb-5 pt-0 pt-lg-5">
                <i class="bi bi-award"></i>
                <span data-purecounter-start="0" data-purecounter-end="22" data-purecounter-duration="2"
                  class="purecounter">{{ count4 + "+" }}</span>
                <p>Awards</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="about" class="about" style="background-image: url('../../assets/HomeImg/bg-1.jpg');padding-bottom:20px;">
    <div class="container aboutSec">
      <div class="row">
        <div class="col-lg-6 pt-3 pt-lg-0 content order-last order-lg-0" data-aos="fade-left">
          <h3>Om Dance Academy</h3>
          <p *ngFor="let paragraph of paragraphs" [innerHTML]="paragraph"></p>
          <ul>
            <li *ngFor="let achievement of achievements">
              <i class="bx bx-check-double"></i>{{ achievement }}
            </li>
          </ul>
        </div>
        <div class="col-lg-6 video-box align-self-baseline position-relative" data-aos="fade-right">
          <img src="../../assets/Gallery/Image-32.jpg" class="img-fluid images1" alt="Om Dance Academy"
            style="height: 330px;">
        </div>
      </div>
    </div>
  </div>
  <!-- about - om dance academy -->
  <section id="about" class="about new-about" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container" style="padding-top: 20px;">
      <div class="row">
        <div class="col-lg-6 video-box align-self-baseline position-relative" data-aos="fade-right">
          <img src="../../assets/HomeImg/academy.jpeg" class="img-fluid images2" alt="" />
        </div>
        <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
          <h3>Omkar Shinde (Founder of ODA)</h3>
          <h2>A Rising Star in the Television Industry</h2>
          <p>
            Omkar Shinde is a versatile artist who initially gained fame as a talented dancer and choreographer in the
            television industry. His breakthrough came with victories in reality shows like Nach Baliye Season 5 and
            Season 9, showcasing his creativity and ability to bring out the best in his dance partners.
          </p>
          <p *ngFor="let about of aboutUs" [innerHTML]="about"></p>
        </div>
      </div>
    </div>
  </section>
  <!-- about -->
  <section id="about" class="about" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container" style="padding-top: 20px;">
      <div class="row">
        <div class="col-lg-6 pt-3 pt-lg-0 content order-last order-lg-0" data-aos="fade-left">
          <h3>Anubhav: Dance Show</h3>
          <p *ngFor="let paragraph of mainPara" [innerHTML]="paragraph"></p>
        </div>
        <div class="col-lg-6 video-box align-self-baseline position-relative" data-aos="fade-right">
          <img src="../../assets/anubhav/IMG_9592.JPG" class="img-fluid" alt="Om Dance Academy">
        </div>
      </div>
    </div>
  </section>
  <!-- cta -->
  <section id="cta" class="cta">
    <div class="container" data-aos="fade-in">
      <div class="text-center">
        <h3>Call To Action</h3>
        <p>Experience the joy of dance with Om Dance Academy. Join now for a free introductory class!
        </p>
        <a class="cta-btn text-decoration-none" href="tel:+91 8669666936">Join Now</a>
      </div>
    </div>
  </section>
  <!-- courses -->
  <section class="courses" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="section-title" data-aos="zoom-in">
      <h2>Our Courses</h2>
    </div>
    <div class="card-container" style="justify-content: space-evenly;">
      <div class="card-hover" *ngFor="let card of cards" data-aos="zoom-in-up">
        <div class="card-hover__content">
          <h3 class="card-hover__title" [innerHTML]="card.title"></h3>
          <p class="card-hover__text" [innerHTML]="card.text"></p>
        </div>
        <img [src]="card.imageUrl" alt="">
      </div>
    </div>
  </section>
  <section id="course" class="course">
    <div class="container" data-aos="fade-in">
      <div class="text-center">
        <h3>Explore Our Courses</h3>
        <p>Experience the joy of dance with Om Dance Academy. Join now for a free introductory class!
        </p>
        <a routerLink="courses" class="coursebtn" (click)="scrollToTop()">Join Now</a>
      </div>
    </div>
  </section>
  <!-- portfolio -->
  <section id="portfolio" class="portfolio" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container">
      <div class="section-title" data-aos="zoom-in">
        <h2>Gallery</h2>
      </div>
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center" data-aos="fade-up">
          <ul id="portfolio-flters">
            <li (click)="filterPortfolio('*')" [ngClass]="{ 'filter-active': activeFilter === '*' }">All</li>
            <li (click)="filterPortfolio('indian')" [ngClass]="{ 'filter-active': activeFilter === 'indian' }">Indian
            </li>
            <li (click)="filterPortfolio('classical')" [ngClass]="{ 'filter-active': activeFilter === 'classical' }">
              Classical</li>
            <li (click)="filterPortfolio('western')" [ngClass]="{ 'filter-active': activeFilter === 'western' }">Western
            </li>
          </ul>
        </div>
      </div>
      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
        <div *ngFor="let item of filteredPortfolio" class="col-lg-4 col-md-6 portfolio-item" [ngClass]="item.category">
          <div class="portfolio-wrap">
            <img [src]="item.imageUrl" class="img-fluid" alt="{{ item.title }}" />
            <div class="portfolio-info">
              <h4>{{ item.title }}</h4>
              <div class="portfolio-links">
                <a href="" data-gallery="portfolioGallery" class="portfolio-lightbox" [title]="item.title"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- faq -->
  <section id="faq" class="faq section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="content px-xl-5">
            <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
            <p>Welcome to Om Dance Academy! We love dance and welcome dancers of all ages and levels. Find more about
              our classes, schedules, performances, and events in the FAQs below.<br>For questions or to try a class,
              just contact us. Let's dance together!</p>
          </div>
        </div>
        <div class="col-lg-8" data-aos="fade-up" data-aos-delay="200">
          <div class="faq-container">
            <div class="faq-item" *ngFor="let faq of faqs; let i = index" [class.faq-active]="faq.isActive">
              <h3 (click)="toggleFaq(i)">
                <span class="num">{{ i + 1 }}.</span> <span>{{ faq.question }}</span>
              </h3>
              <div class="faq-content" *ngIf="faq.isActive">
                <p>{{ faq.answer }}</p>
              </div>
              <i class="faq-toggle bi bi-chevron-right" (click)="toggleFaq(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>