<section id="about-dance" class="about-dance section" (click)="scrollToTop()">
    <img src="../../assets/About-Dance/heroImg.jpeg" alt="hero" data-aos="fade-in">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 data-aos="fade-up" data-aos-delay="100">Om Dance Academy</h2>
            </div>
        </div>
    </div>
</section>
<!-- ABOUT OUR ACADEMY -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>ABOUT OUR ACADEMY</h3>
                <h2>Om Dance Academy</h2>
                <div *ngFor="let section of sections">
                    <p><span class="font-bold">{{ section.title }}</span> {{ section.content }}</p>
                    <ul>
                        <li *ngFor="let achievement of section.achievements">
                            <i class="bx bx-check-double"></i>{{ achievement }}
                        </li>
                    </ul>
                </div>
                <a routerLink="/contact" (click)="scrollToTop()">
                    <button class="read-more-btn">
                        Contact Us
                    </button>
                </a>
            </div>
            <div class="col-lg-6 video-box align-self-baseline position-relative" data-aos="fade-right">
                <img src="../../assets/About-Dance/Students-photo.jpeg" class="dance-img" alt="Om Dance Academy">
            </div>
        </div>
    </div>
</section>
<!-- achievements -->
<section id="counts" class="counts">
    <div class="container">
        <div class="text-center title">
            <h3>What We Have Achieved So Far</h3>
        </div>
        <div class="row counters position-relative">
            <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="9" data-purecounter-duration="2"
                    class="purecounter">{{ count1}}</span>
                <p>Teachers</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="120" data-purecounter-duration="2"
                    class="purecounter">{{ count2 + "+"}}</span>
                <p>Happy Students</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="2"
                    class="purecounter">{{ count3}}</span>
                <p>Years of Experience</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="9" data-purecounter-duration="2"
                    class="purecounter">{{ count4 }}</span>
                <p>Awards</p>
            </div>
        </div>
    </div>
</section>
<!-- OTHER INFORMATION -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About-Dance/ODA-Fam.jpg" class="other-img" alt="dance-3">
                <img src="../../assets/About/group pic.jpeg" class="other-img" alt="dance-3">
            </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>OTHER INFORMATION</h3>
                <h2>Om Dance Academy</h2>
                <p *ngFor="let academy of academy1" [innerHTML]="academy"></p>
            </div>
        </div>
    </div>
</section>
<!-- dance reality shows -->
<section id="dance-features" class="dance-features section section-bg">
    <div class="container">
        <div class="row gy-4 align-items-center features-item">
            <div class="col-lg-5 order-2 order-lg-1 mainText" data-aos="fade-up" data-aos-delay="200">
                <h3 class="text-white">ODA In Dance Reality Shows</h3>
                <ul class="text-white">
                    <li *ngFor="let feature of features">
                        <i class="bx bx-check-double"></i>{{ feature.text }}<span style="color: #a2916a;"> {{
                            feature.season }}</span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                <div class="image-stack">
                    <img src="../../assets/About-Dance/dance-winner-1.jpeg" alt="dance-winner-1" class="stack-front">
                    <img src="../../assets/About-Dance/dance-winner-2.jpeg" alt="dance-winner-2" class="stack-back">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- student achievements -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About-Dance/dance-academy-4.jpeg" class="last-img" alt="dance-3">
            </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>Student Achievements</h3>
                <p>The students of <span class="font-bold">Om Dance Academy </span> have always left an unforgettable
                    mark with their work and
                    performance leaving the judges and audiences nothing but spellbound. The following are the dance
                    reality shows where the students of <span class="font-bold">Om Dance Academy </span> have
                    participated –
                </p>
                <ul>
                    <li *ngFor="let item of achievements">
                        <i class="bx bx-check-double"></i> {{ item.description }}<span style="color: #a2916a;"> ({{
                            item.name }})</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- join oda fam -->
<section id="group" class="group">
    <div class="container">
        <div class="text-center" data-aos="zoom-in">
            <h3>Join Us</h3>
            <div class="social-links">
                <a target="_blank"  href="https://www.youtube.com/@omkarshindesdancecompany11" class="youtube"><i
                        class="bi bi-youtube"></i></a>
                <a target="_blank" href="https://www.facebook.com/p/OM-DANCE-Academy-100063724943566/" class="facebook"><i
                        class="bi bi-facebook"></i></a>
                <a target="_blank" href="https://www.instagram.com/omdanceacademy?igsh=YjJoMmlxd2ZvMnZ1" class="instagram"><i
                        class="bi bi-instagram"></i></a>
                <a target="_blank" href="mailto:osdancecompany06@gmail.com" class="google"><i class="bi bi-google"></i></a>
            </div>
        </div>
    </div>
</section>
<!--  -->