import { Component } from '@angular/core';

@Component({
  selector: 'app-anubhav',
  templateUrl: './anubhav.component.html',
  styleUrls: ['./anubhav.component.scss']
})
export class AnubhavComponent {
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  features = [
    {
      title: 'Introduction',
      description: 'Are you ready to experience the magic of dance like never before? Look no further the Anubhav Reality Show, where you can witness the dance art come to life with different concepts and presentations. This is your chance to immerse yourself in the world of dance and unleash your inner creativity.',
      image: '../../assets/anubhav/intro.jpg'
    },
    {
      title: 'What is Anubhav Reality Show?',
      description: 'Anubhav Reality Show is a dynamic dance reality show that celebrates the unique talents of dancers from diverse backgrounds. It provides a platform for participants to express their passion and showcase their creativity and skills. By bringing together individuals from all walks of life, Anubhav creates a rich tapestry of dance styles and fosters a sense of community and appreciation for the art of dance.',
      image: '../../assets/anubhav/reality-shows.JPG'
    },

    {
      title: 'The Unique Concept of Anubhav',
      description: 'Unlike any other reality show, Anubhav focuses on providing a stage for all ODA dancers to explore their dance dreams in real life. With a blend of creativity, innovation, and talent, Anubhav sets itself apart by offering a platform for dancers to shine and express themselves freely.',
      image: '../../assets/anubhav/5.jpg'
    },
    {
      title: 'Why Anubhav is for All Students of OM Dance Academy?',
      description: 'Anubhav welcomes all students of OM Dance Academy to participate and showcase their talents. Whether you are a beginner or a seasoned dancer, Anubhav offers an inclusive environment where everyone can learn, grow, and excel. It is a community where dancers can come together to support and inspire each other.',
      image: '../../assets/anubhav/festive.jpg'
    },
    {
      title: 'Extraordinary Choreography, Performance, and Presentation',
      description: 'Anubhav is known for its extraordinary choreography, mesmerizing performances, and stunning presentations. Each dance act is meticulously crafted to captivate the audience and leave a lasting impression. With a focus on innovation and creativity, Anubhav pushes the boundaries of dance artistry.',
      image: '../../assets/anubhav/nachbaliye.jpeg'
    },
    {
      title: 'Season 1: Celebrating a Year',
      description: 'spectacular performances by the talented students of Om Dance Academy, who have brought the essence of Hindu festivals to life through mesmerizing Indian classical dance. This Elegant series showcased a variety of festivals such as Dussehra and Diwali, each portrayed gracefully with cultural authenticity. The dedication and artistry of the students not only celebrated these rich traditions but also highlighted the beauty of classical dance, making Anubhav a memorable tribute to India’s vibrant heritage. As we celebrate a year of Anubhav, we honor the incredible talent and hard work of these young dancers who have charmed audiences and brought joy to countless viewers.',
      image: '../../assets/anubhav/season1.JPG',
    },
    {
      title: ' Season 2: अद्वितीय राम एक नृत्यमय कथा',
      description: 'Anubhav is not just a dance anual show; it is a platform where dancers can showcase their unique talents and skills. It is a celebration of dance and expression, bringing together dancers from all walks of life to share their passion for the art form.',
      image: '../../assets/anubhav/season-2.JPG'
    },

  ];
}
