import { Component } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {
  events = [
    {
      id: 1,
      title: 'Sangeet Events',
      description: `Celebrate your special day with the vibrancy of Indian culture and the elegance of Om Dance Academy's Sangeet events, whether you're planning a traditional Indian wedding or a contemporary fusion event. Our expert choreographers will create mesmerizing performances that will leave your guests in awe. From traditional dances to modern fusion, we bring your dreams to life with enchanting dance performances, live music, and immersive cultural experiences.`,
      details: `Whether you're a beginner or experienced dancer, our choreographers ensure you dance with confidence and joy. We offer customized time slots for training, music selection, theme coordination, along with spoke costumes, and excellent audio support with our music system.`,
      image: '../../assets/events/sangeet.avif',
      aos: 'fade-right'
    },
    {
      id: 2,
      title: 'Corporate Events',
      description: `Enhance your corporate events with Om Dance Academy's artistic touch. Whether you're organizing a prestigious conference, an elegant gala dinner, or a dynamic product launch, our team specializes in creating memorable experiences that align perfectly with your corporate identity. We showcase amazing live performances themed to match your event, ensuring every detail contributes to the impactful occasion.`,
      details: `With sophisticated choreographed routines to ambient music and thematic decor, our approach blends creativity with professionalism, guaranteeing that your guests are not just entertained but also inspired. Let Om Dance Academy transform your corporate event into a sophisticated affair that leaves a lasting impression on clients, partners, and employees alike.`,
      extraDetails: ``,
      image: '../../assets/events/corporate.jpg',
      aos: 'fade-left'
    }
  ];
}
