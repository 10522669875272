import { Component } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-about-academy',
  templateUrl: './about-academy.component.html',
  styleUrls: ['./about-academy.component.scss']
})
export class AboutAcademyComponent {
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  count1: number = 0;
  count2: number = 0;
  count3: number = 0;
  count4: number = 0;

  constructor() { }
  ngAfterViewInit(): void {
    AOS.init({
      offset: 120,
      duration: 700,
    });
    console.log('animation');
  }

  accuratecountstop: any = setInterval(() => {
    this.count1++;
    if (this.count1 == 9) {
      clearInterval(this.accuratecountstop);
    }
  }, 400);

  revenuecountstop: any = setInterval(() => {
    this.count2++;
    if (this.count2 == 120) {
      clearInterval(this.revenuecountstop);
    }
  }, 70);

  counterstop: any = setInterval(() => {
    this.count3++;
    if (this.count3 == 15) {
      clearInterval(this.counterstop);
    }
  }, 400);

  countstop: any = setInterval(() => {
    this.count4++;
    if (this.count4 == 22) {
      clearInterval(this.countstop);
    }
  }, 300);

  achievements = [
    { description: 'Winners of Maharashtracha Favourite Dancer', name: 'Nehul & Samiksha' },
    { description: 'Winner of HDFC Life Youngstars, season 2, 2016', name: 'Mitali Nimbalkar' },
    { description: 'Dil se NAche India wale, Zee Marathi, Semi-finalist', name: 'Prajakta Dimble' },
    { description: 'Maharashtra’s Best Dancer semi-finalist, Top 6, 2020-21', name: 'Arya Dongre' },
    { description: 'Maharashtracha Dancing Superstar, Top 7, Zee tv', name: 'Prajakta Dimble' },
    { description: '2MAD, Top 8, Colours Marathi,', name: 'Mitali Nimbalkar' },
    { description: '2MAD – Top 10, 2017', name: 'Arya Dongre' },
    { description: 'Maharashtrachi Lavnyavati, Top 12', name: 'Prajakta Dimble' },
    { description: 'MAD Top 16, Etv Marathi, season 2', name: 'Mitali Nimbalkar' },
    { description: 'India’s Dancing Superstar – Top 32, 2013', name: 'Arya Dongre' },
    { description: 'Maharashtracha Favorite Kon? 2015', name: 'Arya Dongre' },
    { description: 'Super Dancer Maharahstra, final audition round, 2018', name: 'Arya Dongre' },
    { description: 'India’s Best Dancer, Top 32, Mega Audition Round ', name: 'Arya Dongre' },
  ];
  sections = [
    {
      title: 'Om Dance Academy',
      content: 'is a registered dance academy started by reality show dancer and choreographer Omkar Shinde. It offers training in dance, stunts, fitness, and more with expert trainers.',
      achievements: [
        'Winning team of Dance Maharashtra Dance, Season 2',
        'Semi-finalists in India’s Got Talent, Season 8',
        'Dance Plus Season 2 – Top 12'
      ]
    }
  ];
  academy1: string[] = [
    `At the age 18, Omkar Shinde started <span class="font-bold">Om Dance Academy </span>
    to provide an opportunity to aspiring dancers to learn professionally and build a career in the
    dance industry. Is is an institute that provides artistic services ranging from dance and fitness
    classes right from kids to adults and is also actively involved in conducting various workshops, for
    various classical and folk-dance styles, art of living and meditation sessions, conducting dance
    camps for students and instructors, etc.`,
    `Classes involve choreographies, technique learning, reality show rehearsals, stunt and gymnastic
    batches, polishing and practice sessions. An environment where instructors personally look into the
    attendance of each student and ensure and encourage their presence in every class. Each student’s
    journey and growth is a paramount consideration and is given utmost priority.`,
    `Various batches are conducted at <span class="font-bold">Om Dance Academy </span> beginning at 7am
     in the morning to 9pm at night.
     It is the perfect place not only for youngsters but also for kids and even adults and senior
     citizens, as we provide wide range of classes and activities suiting to all age groups.`,
    `<span class="font-bold">Om Dance Academy </span> is fully furnished and well-maintained studio,
     located in the heart of Pune city, in
     Kothrud. Fully equipped with full-sized mirrors, properly ventilated, sound proof windows, and is
     spacious enough to easily accommodate 25 + students who will be able to get ample space to dance
     their hearts out.`
  ];
  features = [
    { text: 'Winning team of Dance Maharashtra Dance,', season: 'Season 2' },
    { text: 'Qualifying Semi finale team in India’s Got Talent,', season: 'Season 8' },
    { text: 'Top 12 qualifying team in Dance Plus', season: 'Season 2' }
  ];
}