<section id="hero">
    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <ol class="carousel-indicators" id="hero-carousel-indicators">
            <li *ngFor="let slide of carouselSlides; let i = index" [attr.data-bs-target]="'#heroCarousel'"
                [attr.data-bs-slide-to]="i" [class.active]="i === 0"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
            <div *ngFor="let slide of carouselSlides; let i = index" class="carousel-item" [class.active]="i === 0"
                [ngStyle]="{'background-image': 'url(' + slide.image + ')'}">
                <div class="carousel-container">
                    <div class="container">
                        <h2 class="animate__animated animate__fadeInDown" [innerHTML]="slide.title"></h2>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </a>
        <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </a>
    </div>
</section>
<!-- about his career -->
<section id="features" class="features">
    <div class="containers">

        <div class="row gy-4 align-items-center features-item">

            <div class="info" class="col-lg-5 order-2 order-lg-1 mainText" data-aos="fade-up" data-aos-delay="200">
                <h3>About His Career</h3>
                <p *ngFor="let career of career1" [innerHTML]="career"></p>
            </div>


            <div class="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                <div class="image-stack">
                    <img src="../../assets/About/123.jpeg" alt="" class="stack-front">
                    <!-- <img src="../../assets/About/about-career2.jpg" alt="" class="stack-back"> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about onkar shinde -->
<section id="about" class="about section section-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>Founder of ODA</h3>
                <h2>MR. OMKAR SHINDE</h2>
                <p *ngFor="let about of aboutItems" [innerHTML]="about"></p>
                <div class="social-links">
                    <a target="_blank" href="https://youtu.be/MTo0dxRp2JY?si=OAzfgHipSMqmMYz5" class="youtube"><i
                            class="bi bi-youtube"></i></a>
                    <a target="_blank" href="https://www.instagram.com/omkarshinde1?igsh=amFpcThuNmZvNXY1"
                        class="instagram"><i class="bi bi-instagram"></i></a>
                    <a target="_blank" href="https://x.com/i/flow/login?redirect_after_login=%2Fomkarshinde_om"
                        class="twitter"><i class="bi bi-twitter"></i></a>
                </div>
            </div>
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About/about.jpeg" class="main-img" alt="">
            </div>
        </div>
    </div>
</section>
<!-- Television and Award shows -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="containers">
        <div class="row">
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About/television-awards.jpeg" class="award-img" alt="">
            </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3 style="color: #5c5b5b;">Television & Award Shows</h3>
                <ul>
                    <li *ngFor="let award of awards">
                        <i class="bx bx-check-double"></i>{{ award }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- The choreographer journey  -->
<section id="about" class="about section section-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>The Choreographer Journey</h3>
                <p>He has choreographed many reality shows like:</p>
                <ul>
                    <li *ngFor="let show of shows">
                        <i class="bx bx-check-double"></i>{{ show }}
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About/choreographer.jpg" class="main-img" alt="">
            </div>
        </div>
    </div>
</section>
<!-- MEET US  -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="containers">
        <div class="row">
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About-Dance/meetus.JPG" class="img5" alt="">
            </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3 style="color: #5c5b5b;">MEET US</h3>
                <h2>Our Team</h2>
                <p *ngFor="let meet of meetUs" [innerHTML]="meet"></p>
            </div>
        </div>
    </div>
</section>
<!-- INSTRUCTORS OF OM DANCE ACADEMY -->
<section id="about" class="about section section-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left">
                <h3>The instructors of Om Dance Academy have assisted Omkar Shinde for various reality show
                    choreographies/Songs, Shoots, etc.</h3>
                <ul>
                    <li *ngFor="let item of realityShows">
                        <i class="bx bx-check-double"></i> {{ item.description }}<span style="color: #a2916a;"> ({{
                            item.name }})</span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 video-box align-self-baseline position-relative " data-aos="fade-right">
                <img src="../../assets/About/reality-shows.jpeg" class="img-fluid" alt="">
            </div>
        </div>
    </div>
</section>
<!-- teams & Departments -->
<section id="about" class="about section" style="background-image: url('../../assets/HomeImg/bg-1.jpg');">
    <div class="containers">
        <div class="row">
            <div class="col-lg-12 pt-3 pt-lg-0 content" data-aos="fade-in">
                <h3>Other Teams & Departments</h3>
                <p *ngFor="let paragraph of paragraphs" [innerHTML]="paragraph"></p>
            </div>
        </div>
    </div>
</section>
<!-- team -->
<section id="group" class="group">
    <div class="container">
        <div class="text-center" data-aos="zoom-in">
            <h3>Join Us</h3>
            <div class="social-links">
                <a target="_blank" href="https://www.youtube.com/@omkarshindesdancecompany11" class="youtube"><i
                        class="bi bi-youtube"></i></a>
                <a target="_blank" href="https://www.facebook.com/p/OM-DANCE-Academy-100063724943566/"
                    class="facebook"><i class="bi bi-facebook"></i></a>
                <a target="_blank" href="https://www.instagram.com/omdanceacademy?igsh=YjJoMmlxd2ZvMnZ1"
                    class="instagram"><i class="bi bi-instagram"></i></a>
                <a target="_blank" href="mailto:osdancecompany06@gmail.com" class="google"><i
                        class="bi bi-google"></i></a>
            </div>
        </div>
    </div>
</section>
<!--  -->