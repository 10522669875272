<header id="header" class="fixed-top d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="logo">
      <a routerLink="/" (click)="scrollToTop()"> <img src="../../assets/HomeImg/new-logo.jpeg" /></a>
    </div>
    <nav id="navbar" class="navbar order-last order-lg-0">
      <ul class="desktop-menu d-flex justify-content-center" *ngIf="!isMenuOpen">
        <li><a class="nav-link" routerLink="/" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" (click)="scrollToTop()">Home</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="about" routerLinkActive="active">About</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="omkar-shinde" routerLinkActive="active">Omkar
            Shinde</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="events" routerLinkActive="active">Events</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="courses" routerLinkActive="active">Courses</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="anubhav" routerLinkActive="active">Anubhav</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="gallery" routerLinkActive="active">Gallery</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink="contact" routerLinkActive="active">Contact</a></li>
      </ul>
      <nav class="navbar order-last order-lg-0 navbar-mobile" *ngIf="isMenuOpen">
        <ul>
          <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="/" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Home</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="about"
              routerLinkActive="active">About</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="omkar-shinde"
              routerLinkActive="active">Omkar Shinde</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="events"
              routerLinkActive="active">Events</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="courses"
              routerLinkActive="active">Courses</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="anubhav"
              routerLinkActive="active">Anubhav</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="gallery"
              routerLinkActive="active">Gallery</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="contact"
              routerLinkActive="active">Contact</a></li>
        </ul>
        <i class="bi mobile-nav-toggle" [ngClass]="{'bi-x': isMenuOpen, 'bi-list': !isMenuOpen}"
          (click)="toggleMenu()"></i>
      </nav>
      <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-list': !isMenuOpen}" (click)="toggleMenu()"></i>
    </nav>
    <div class="social-links">
      <a target="_blank" href="https://www.youtube.com/@omkarshindesdancecompany11"><i class="bi bi-youtube"></i></a>
      <a target="_blank" href="https://www.facebook.com/p/OM-DANCE-Academy-100063724943566/"><i class="bi bi-facebook"></i></a>
      <a target="_blank" href="https://www.instagram.com/omdanceacademy?igsh=YjJoMmlxd2ZvMnZ1"><i class="bi bi-instagram"></i></a>
      <a target="_blank" href="mailto:osdancecompany06@gmail.com"><i class="bi bi-google"></i></a>
    </div>
  </div>
</header>