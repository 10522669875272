import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeModule } from './home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AboutModule } from './omkar-shinde/about.module';
import { ContactModule } from './contact/contact.module';
import { AboutAcademyModule } from './about-academy/about-academy.module';
import { CoursesModule } from './courses/courses.module';
import { AnubhavModule } from './anubhav/anubhav.module';
import { EventsModule } from './events/events.module';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HomeModule,
    AboutModule,
    ContactModule,
    AboutAcademyModule,
    CoursesModule,
    AnubhavModule,
    EventsModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
