import { Component } from '@angular/core';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent {
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  cards = [
    {
      no: '01',
      title: 'Kids Training',
      content: 'We believe in nurturing the love for dance in children from a young age. Our professional instructors provide a supportive and fun environment where kids can learn various dance styles, develop their skills, and build confidence.',
      img: '../../assets/Courses/kids-dance.jpg'
    },
    {
      no: '02',
      title: 'Freestyle',
      content: 'Hip hop classes include extensive foot works, techniques, etc. Choreographies are taught and fusion styles are also introduced depending on the genre and mood of the song. In this process the students get to explore basics of other dance forms like locking and popping, wacking, urban styles etc.',
      img: '../../assets/Courses/freestyle.jpg'
    },
    {
      no: '03',
      title: 'Contemporary ',
      content: 'Students are trained in techniques, floor work elements, flow, usage of props, etc. with the help of songs and choreographies. Freestyle sessions are also conducted where songs are played which are unchoreographed and the student has the freedom to improvise on the spot and let his creative side out as a dancer.',
      img: '../../assets/Courses/Contemporary.jpg'
    },
    {
      no: '04',
      title: 'Special Training',
      content: 'Our special training batch for dance is designed for individuals who are passionate about dancing and wish to refine their skills. Whether you are a beginner looking to learn the basics or an advanced dancer aiming to perfect your technique, this batch offers personalized attention and expert guidance.',
      img: '../../assets/Courses/special-dance.jpg'
    },
    {
      no: '05',
      title: 'Dance Fitness',
      content: 'We offer specialized dance training programs tailored for dancers who seek intensive coaching, advanced techniques, and performance opportunities. Our special dance training programs are designed for those who are passionate about dance and wish to take their skills to the next level.',
      img: '../../assets/Courses/dance-fitness.jpg'
    },
    {
      no: '06',
      title: 'Zumba',
      content: 'Zumba is a high-energy dance fitness program that combines Latin and international music with fun dance moves. It’s designed to make you forget you are working out by creating a party- like atmosphere.Suitable for all fitness levels, improve health, and boost your mood.',
      img: '../../assets/Courses/zumba-dance.jpg'
    },
    {
      no: '07',
      title: 'Personal Training',
      content: 'Personal training sessions are conducted for all age groups, for all dance styles depending upon the need and requirements of the students.',
      img: '../../assets/Courses/personal-training.jpg'
    },
    {
      no: '08',
      title: 'Reality Show',
      content: 'Our instructors choreograph stunning audition performances, covering expressions, props, song choices, costumes, and more. They also guide participants on making a lasting impression on judges.',
      img: '../../assets/Courses/reality-show.jpg'
    },
    {
      no: '09',
      title: 'Kathak',
      content: 'Kathak is one of the eight classical dance forms of India, known for its intricate footwork, graceful movements, and expressive storytelling through dance. Originating from the northern regions of India, Kathak is a beautiful blend of rhythm, drama, and devotion.',
      img: '../../assets/Courses/khatak.jpg'
    },
    {
      no: '10',
      title: 'Bharatnatyam',
      content: 'Bharatnatyam is a taught keeping in mind the syllabus and gradually goes from basic to advance. Mudras, adavus, posture correctness, expressions, etc. are taught and practiced.',
      img: '../../assets/Courses/khatak-1.jpg'
    },
    {
      no: '11',
      title: 'Gymnastics',
      content: 'At Om Dance Academy, stunts are taught under expert supervision using professional gymnastic mats for safety. Our instructors follow a step-by-step technique, focusing on muscle strengthening before introducing any stunts.',
      img: '../../assets/Courses/Gymnastics.jpeg'
    },
    {
      no: '12',
      title: 'Ladies',
      content: 'At Om Dance Academy, Our ladies dance classes offer a perfect blend of fitness and enjoyment, helping you stay active while having a great time.',
      img: '../../assets/Courses/ladies-batch.webp'
    },
  ];
}
