import { Component } from '@angular/core';
import * as AOS from 'aos';

interface FAQ {
  question: string;
  answer: string;
  isActive: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  count1: number = 0;
  count2: number = 0;
  count3: number = 0;
  count4: number = 0;

  constructor() { }
  ngAfterViewInit(): void {
    AOS.init({
      offset: 120,
      duration: 700,
    });
    console.log('animation');
  }

  accuratecountstop: any = setInterval(() => {
    this.count1++;
    if (this.count1 == 120) {
      clearInterval(this.accuratecountstop);
    }
  }, 30);

  revenuecountstop: any = setInterval(() => {
    this.count2++;
    if (this.count2 == 9) {
      clearInterval(this.revenuecountstop);
    }
  }, 400);

  counterstop: any = setInterval(() => {
    this.count3++;
    if (this.count3 == 15) {
      clearInterval(this.counterstop);
    }
  }, 250);

  countstop: any = setInterval(() => {
    this.count4++;
    if (this.count4 == 22) {
      clearInterval(this.countstop);
    }
  }, 180);

  cards = [
    {
      title: 'Indian',
      text: 'Experience the vibrant world of Indian dance at Om Dance Academy. From Classical forms like Bharatanatyam and Kathak to energetic Bollywood and colorful Folk dances. Led by expert instructors, we focus on technique, expression, and cultural. Join us to explore the rhythm of Indian dance!',
      learnHowText: 'Learn How',
      imageUrl: '../../assets/HomeImg/traditional-course.jpg',
    },
    {
      title: 'Freestyle Hip hop',
      text: `Freestyle hip hop is a fundamental and expressive aspect of hip hop culture. It features spontaneous rapping created on the spot, without pre-written lyrics. This improvisational style highlights the rapper's creativity, lyrical agility, and impressive quick thinking ability.`,
      learnHowText: 'Learn How',
      imageUrl: '../../assets/HomeImg/frestyle1.jpg',
    },
    {
      title: 'Western',
      text: 'Discover Contemporary, Jazz, Hip-Hop, and more at Om Dance Academy. Our classes cater to all levels, emphasizing technique, creativity, and performance skills. Join us to improve fitness, refine technique, and explore the artistry of Western dance in a supportive environment.',
      learnHowText: 'Learn How',
      imageUrl: '../../assets/HomeImg/western.jpg',
    },
  ];
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  activeFilter: string = '*';
  portfolioData: any[] = [
    {
      title: 'Western',
      category: 'western',
      imageUrl: '../../assets/HomeImg/western-gallery.jpg',

    },
    {
      title: 'Indian',
      category: 'indian',
      imageUrl: '../../assets/HomeImg/tradition3.jpg',
    },
    {
      title: 'Classical',
      category: 'classical',
      imageUrl: '../../assets/HomeImg/classical.jpg',
    },
  ];


  filteredPortfolio: any[] = this.portfolioData;
  filterPortfolio(category: string): void {
    if (category === '*') {
      this.filteredPortfolio = this.portfolioData;
    } else {
      this.filteredPortfolio = this.portfolioData.filter(item => item.category === category);
    }
    this.activeFilter = category;
  }
  aboutUs: string[] = [
    `            Beyond his success in dance, Omkar Shinde ventured into acting, starring as the lead actor in the 2017
            Marathi film "Anaan" alongside actress Prarthana Behere. The movie, celebrated for its heartfelt narrative
            and stellar performances, marked his transition into a prominent figure in both dance and acting realms.`,
    `Omkar Shinde's journey exemplifies his dedication and versatility in the entertainment industry. With a
            captivating presence and a passion for storytelling, he continues to inspire audiences and aspiring artists
            alike, setting new benchmarks of excellence in every role he undertakes.`
  ];

  paragraphs = [
    'Om Dance Academy, founded by reality show dancer and choreographer Omkar Shinde, is a registered institution known for its excellence in reality show dance training, fitness classes, skill enhancement, and more.',
    'The academy boasts expert faculty members specializing in dance, artistic expression, and physical fitness, committed to nurturing talent and pushing boundaries in the world of dance with innovative training methods.'
  ];

  mainPara = [
    `Anubhav is a unique dance anual show that celebrates dance and expression by providing a platform for all students of OM Dance Academy to showcase their talents. Unlike other reality shows, Anubhav focuses on creativity, innovation, and inclusivity, welcoming dancers of all levels to explore their dance dreams in a supportive community.`,
    `Known for its extraordinary choreography, mesmerizing performances, and stunning presentations, Anubhav captivates audiences and pushes the boundaries of dance artistry. It offers a stage where dancers can shine and express themselves freely, making each act a meticulously crafted experience that leaves a lasting impression.`
  ]

  achievements = [
    'Champions of Dance Maharashtra Dance Season 2',
    'Semi-finalists in India’s Got Talent Season 8',
    'Top 12 contestants on Dance Plus Season 2'
  ];

  faqs: FAQ[] = [
    {
      question: 'What styles of dance do you offer?',
      answer: 'We offer a variety of dance styles including Indian Classic, Bharatnatyam, ballet, jazz, hip-hop, contemporary, tap, and more. Our classes cater to all ages and skill levels.',
      isActive: false
    },
    {
      question: 'What age groups do you cater to?',
      answer: 'We have classes available for children as young as 5 years old up to adults of any age. We offer age-appropriate classes to ensure everyone can enjoy dancing.',
      isActive: false
    },
    {
      question: 'Are there any performance opportunities?',
      answer: 'We annually organize live reality show performances where all students can showcase their skills. Participation is optional but encouraged for growth and confidence.',
      isActive: false
    },
    {
      question: 'What qualifications do your instructors have?',
      answer: 'Our instructors are highly qualified professionals with extensive experience in their respective dance styles. Many of them are accomplished dancers who have also performed in movies, daily soaps, and reality shows.',
      isActive: false
    },
    {
      question: 'What should students wear to class?',
      answer: 'Students should wear appropriate dance attire such as leotards, tights, leggings, dance shoes (specific to their style), and hair securely tied back. Specific requirements will vary by class.',
      isActive: false
    },
    {
      question: 'What are your tuition fees?',
      answer: 'Tuition fees vary depending on the course. Please refer to our current class schedule or contact us directly for detailed information regarding fees and payment options.',
      isActive: false
    },
    {
      question: 'Do you offer choreography services for sangeet and corporate events?',
      answer: 'Yes, we specialize in creating choreographed performances tailored for weddings, sangeet ceremonies, corporate events (including conferences, gala dinners, product launches), and parties.',
      isActive: false
    },
    {
      question: 'What other services do you provide for sangeet and corporate events?',
      answer: ' Our comprehensive services consists of customized dance routines, music selection, costume coordination, and delivering a polished performance that harmonizes perfectly with your events theme.',
      isActive: false
    },
    {
      question: 'Can you teach dance to family members and friends for the Sangeet?',
      answer: ' Absolutely! We offer dance classes specifically designed for family members and friends participating in Sangeet performances. Whether you are a beginner or an experienced dancer, our instructors will ensure everyone feels confident and ready to perform.',
      isActive: false
    },
    {
      question: 'What dance styles do you recommend for Sangeet performances?',
      answer: 'We recommend a variety of dance styles such as Bollywood, classical, folk, and contemporary, depending on the theme and preferences of the couple.',
      isActive: false
    },
  ];

  toggleFaq(index: number) {
    this.faqs[index].isActive = !this.faqs[index].isActive;
    this.faqs.forEach((faq, i) => {
      if (i !== index) {
        faq.isActive = false;
      }
    });
  }

  carouselSlides = [
    {
      image: '../../assets/HomeImg/download.png',
      title: 'Welcome To Om Dance Academy',
      buttonText: 'Explore Our Courses',
      buttonLink: 'courses'
    },
    {
      image: '../../assets/HomeImg/dance-pic.JPG',
      title: 'Welcome To Om Dance Academy',
      buttonText: 'Explore Our Courses',
      buttonLink: 'courses'
    },
    {
      image: '../../assets/HomeImg/group-pic.jpeg',
      title: 'Welcome To Om Dance Academy',
      buttonText: 'Explore Our Courses',
      buttonLink: 'courses'
    }
  ];
}
